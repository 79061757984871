import BaseService from '@/services/BaseService'

class SettingsService extends BaseService {
  constructor() {
    super()
  }

  // Get All ALI-2 Settings
  async getSettings(opts = {}) {
    this.endPoint = 'api/v2/global-settings'
    // this.endPoint = 'api/global-settings?country_id=99'
    return await this.get(opts)
  }
  async createSettings(data, opts = {}){
    this.endPoint = 'api/v2/global-settings/bulk-create'
    return await this.post(data, opts)
  }
  async updateSettings(data, opts = {}) {
    // this.endPoint = 'api/global-settings'
    this.endPoint = 'api/v2/global-settings/bulk-update'
    return await this.put(data, opts)
  }
  async deleteSettings(data) {
    this.endPoint = 'api/v2/global-settings/bulk-delete'
    return await this.bulkDeleteGlobalSetting(data)
  }
  async enable(data, opts = {}) {
    this.endPoint = 'api/v2/enable-volume-weight-charge-by-role'
    return await this.put(data, opts)
  }
  async disable(data, opts = {}) {
    this.endPoint = 'api/v2/disable-volume-weight-charge-by-role'
    return await this.put(data, opts)
  }
}

export default new SettingsService()
