import Baseservice from '@/services/BaseService'

class CreateOrderService extends Baseservice {
  constructor(){
    super()
  }
  calculation(data= {}){
    this.endPoint= 'api/v2/calculation'
    return this.post(data).then(respone => {
      return respone.data
    })
  }

  calculationNonCatalog(data= {}){
    this.endPoint= 'api/v2/calculation-noncatalog'
    return this.post(data).then(respone => {
      return respone.data
    })
  }

  addProducts(data = {}) {
    this.endPoint = 'api/v2/carts'
    return this.post(data).then(response => {
      return response.data
    })
  }

  createOrder(id, data= {}){
    this.endPoint = `api/v2/cart/${id}/checkout`
    return this.post(data).then(response => {
      return response.data
    })
  }

  deleteItem(item_id){
    this.endPoint = 'api/v2/cart-items'
    return this.delete(item_id).then(response => {
      return response.data
    })
  }

  // /api/v2/cart/{cart_id}/clear
  deleteAllCartItem (cart_id) {
    // this.endPoint = `api/v2/cart/${cart_id}/clear`
    this.endPoint = `api/v2/cart/${cart_id}/delete`
    return this.rawDelete().then(response => {
      return response.data
    })

    // this.endPoint = '/api/v2/cart'+cart_id+'/clear'
    // return this.delete(item_id).then(response => {
    //   return response.data
    // })
    // return this.delete(id, opts)
  }


}

export default new CreateOrderService()